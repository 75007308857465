/* eslint-env browser */
import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { navigate } from 'gatsby';
import Button from './button';
import './styled-components/helper.scss';
import { encode, axiosInstance } from '../../util/formUtils';

import {
  EmailFormBox,
  Input,
  OneInputContainer,
  Label,
  TwoInputContainer,
} from './styled-components/formStyledComponents';

const formName = 'MailingList';

const handleSubmit = (values, setSubmitting) => {
  axiosInstance.post('/',encode({
      'form-name': formName,
      ...values
    }))
    .then(() => {
      setSubmitting(false);
      navigate('/thanks', { state: { mode: 'email-list' } });
    })
    .catch(error => alert(error));
};

const MailingListForm = () => (
  <EmailFormBox>
    <Formik
      initialValues={{ email: '', botField: '' }}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting);
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email()
          .required('Email Required'),
      })}
    >
      {props => {
        const {
          values,
          touched,
          errors,
          dirty,
          isSubmitting,
          handleChange,
          handleBlur,
          handleSubmit,
          handleReset,
        } = props;
        return (
          <form
            name={formName}
            method="post"
            action="/"
            data-netlify="true"
            data-netlify-honeypot="botField"
            onSubmit={handleSubmit}
          >
            <OneInputContainer>
              {/*bot field*/}
              <Label htmlFor="botField" style={{ display: 'none' }}>
                Don’t fill this out if you are human:
              </Label>
              <Input
                id="botField"
                name="botField"
                placeholder="Bot Field"
                type="text"
                value={values.botField}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.botField && touched.botField
                    ? 'text-input error'
                    : 'text-input'
                }
                style={{ display: 'none' }}
              />
              {/*email*/}
              <Label htmlFor="email" style={{ display: 'block' }}>
                Email
              </Label>
              <Input
                id="email"
                name="email"
                placeholder="Enter your email"
                type="email"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                className={
                  errors.email && touched.email
                    ? 'text-input error'
                    : 'text-input'
                }
              />
              {errors.email && touched.email && (
                <div className="input-feedback">{errors.email}</div>
              )}
            </OneInputContainer>

            <TwoInputContainer>
              <Button
                type="submit"
                disabled={isSubmitting}
                text="submit"
                small
              />
              <Button
                type="button"
                className="outline"
                onClick={handleReset}
                disabled={!dirty || isSubmitting}
                text="Reset"
                small
                gray
              />
            </TwoInputContainer>
          </form>
        );
      }}
    </Formik>
  </EmailFormBox>
);

MailingListForm.propTypes = {
  work: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.bool,
  errors: PropTypes.object,
  dirty: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleReset: PropTypes.func,
};

export default MailingListForm;
